/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { Suspense } from 'react'
import dynamic from "next/dynamic";
import Head from "next/head";
import bgImage from "/assets/img/bg-home-small.jpg"; // experimenting with -small
import { findProjectsForHomePage } from '/pages/api/our-work';
import { findArticlesForHomePage } from '/pages/api/posts';
import { findVideoReviewsForHomePage } from "/pages/api/reviews";
import config from '/config';

const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const PageLayout = dynamic(() => import("/examples/PageLayout"), { loading: () => null });
const BodyCard = dynamic(() => import('/examples/BodyCard'), { loading: () => null });
const HeroBanner = dynamic(() => import('/examples/HeroBanner'), { loading: () => null });
const Information = dynamic(() => import('/pageSections/Home/information'), { loading: () => null });
const Reviews = dynamic(() => import('/pageSections/Home/Reviews'), { loading: () => null });
const ReviewsSlider = dynamic(() => import('/pageSections/Home/ReviewsSlider'), { loading: () => null });
const OurWork = dynamic(() => import('/pageSections/Home/OurWork'), { loading: () => null });
const PatioConfigurator = dynamic(() => import('/pageSections/Home/PatioConfigurator'), { loading: () => null });
const OurProcess = dynamic(() => import('/pageSections/Home/OurProcess'), { loading: () => null });
const OurArticles = dynamic(() => import('/pageSections/Home/OurArticles'), { loading: () => null });
const ContactUs = dynamic(() => import('/examples/ContactUs'), { loading: () => null });
const GoogleAnalytics = dynamic(() => import('/examples/GoogleAnalytics'), { loading: () => null });

function Home({ articleCards, projectCards, videoReviews }) {
  return (
    <>
      <Head>
        <title>Long Island's Top Rated Landscaping Company | Autumn Leaf</title>
        <meta name="description" key="desc" content="Check out Long Island's Top Rated Landscaping Company. Landscape Design & Build Services for Outdoor Living, Cooking and Entertaining." />
        <meta property="og:site_name" content="Autumn Leaf Landscape" />
        <meta property="og:url" content={config.frontURL} />
        <meta property="og:title" content="Check out Autumn Leaf" />
        <meta property="og:description" content="Check out Long Island's Top Rated Landscaping Company. Landscape Design & Build Services for Outdoor Living, Cooking and Entertaining." />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content={`${config.frontURL}/images/social/home-facebook.jpg`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="photo" />
        <meta name="twitter:title" content="Autumn Leaf" />
        <meta name="twitter:description" content="Landscaping Company on Long Island, NY" />
        <meta name="twitter:url" content={config.frontURL} />
        <meta name="twitter:image" content={`${config.frontURL}/images/social/home-twitter.jpg`} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "GeneralContractor",
              "name": "Autumn Leaf Landscape Design, Inc.",
              "image": `${config.frontURL}/logo.png`,
              "@id": config.frontURL,
              "url": config.frontURL,
              "telephone": "(631) 424-5544",
              "email": "lenny@autumnleaflandscape.com",
              "priceRange": "$$$",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "100 Centershore Road",
                "addressLocality": "Centerport",
                "addressRegion": "NY",
                "postalCode": "11721",
                "addressCountry": "US"
              },
              "areaServed": {
                "@type": "GeoCircle",
                "geoMidpoint": {
                  "@type": "GeoCoordinates",
                  "latitude": 40.892241,
                  "longitude": -73.374337
                },
                "geoRadius": 100000
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 40.892241,
                "longitude": -73.374337
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "10:00",
                "closes": "17:00"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "bestRating": 5,
                "worstRating": 1,
                "ratingValue": 5,
                "reviewCount": 46
              }
            }
            )
          }}
        />
      </Head>
      <GoogleAnalytics />
      <PageLayout transparent light>
        <HeroBanner
          height="75vh"
          image={bgImage.src}
          imageAlt="Autumn Leaf Owner Lenny at one of our outdoor design and build projects"
          prefix1="Landscape Design & Build "
          prefix2="Services for"
          typedJSContent={[" Outdoor Living", " Outdoor Cooking", " Outdoor Entertaining", " Outdoor Playing", " Your Dream Yard"]}
          subtitle="Our Process is easy so you can enjoy it as well as the finished project."
          buttons={["phone", "ourwork"]}
        />
        <BodyCard>
          <Information />
          <br />
          <Suspense fallback={<p>Loading...</p>}>
            <Reviews videoReviews={videoReviews} />
          </Suspense>
          <Suspense fallback={<p>Loading...</p>}>
            <ReviewsSlider />
          </Suspense>
          <Suspense fallback={<p>Loading...</p>}>
            <OurWork projectCards={projectCards} />
          </Suspense>
          <Suspense fallback={<p>Loading...</p>}>
            <PatioConfigurator />
          </Suspense>
          <br />
          <Suspense fallback={<p>Loading...</p>}>
            <OurProcess />
          </Suspense>
          <br />
          <Suspense fallback={<p>Loading...</p>}>
            <OurArticles articleCards={articleCards} />
          </Suspense>
          <br />
          <MDBox id="message">
            <Suspense fallback={<p>Loading...</p>}>
              <ContactUs />
            </Suspense>
          </MDBox>
        </BodyCard>
      </PageLayout >
    </>
  );
}

export default Home;


export async function getStaticProps() {
  const articleCards = findArticlesForHomePage();
  const projectCards = findProjectsForHomePage();
  const videoReviews = findVideoReviewsForHomePage();
  return { props: { articleCards, projectCards, videoReviews } };
}

