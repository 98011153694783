const frontURL = "https://autumnleaflandscape.com"; // "http://localhost:3000";  // "http://localhost:3000"; // "https://autumnleaflandscape-com.vercel.app"; // 
const FbURL = "http://www.facebook.com/AutumnLeafLandscapeDesign";
const TwitterURL = "https://twitter.com/autumnleafny";
const InstagramURL = "https://www.instagram.com/autumnleafny/";
const YoutubeURL = "http://www.youtube.com/user/AutumnLeafNY";

const exportedObject = {
  frontURL,
  FbURL,
  TwitterURL,
  InstagramURL,
  YoutubeURL
};
export default exportedObject;
